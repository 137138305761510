/**
 * Newsletter subscribe section JS
 */
(function($) {

  let form_fields = {
    'form': $('form.newsletter-subscribe'),
    'email_field': $('input#md-subscribe-email'),
    'checkbox': $('#md-newsletter-subscribe-agree'),
    'btn': $('button.newsletter-subscribe-submit'),
    'response_container': $('section.newsletter-subscribe-section div.response-container')
  };

  function md_public_ajax(data, $elem) {

    //security:
    if (md_ajax_object.ajax_nonce !== undefined && md_ajax_object.ajax_nonce.length) {
      data.security = md_ajax_object.ajax_nonce;
    }

    jQuery.post(md_ajax_object.ajax_url, data, function (response) {

      let ajax_response = JSON.parse(response);
      $elem.message_container.html('<p class="'+ajax_response.status+'">'+ajax_response.message+'</p>');

      form_fields.btn.removeClass('sending');

      //Successfully subscribed? Clear the form
      if (ajax_response.status === 'success') {
        form_fields.email_field.val('');
        form_fields.checkbox.prop('checked', false);
      }

    });
  }//end md_public_ajax


  $(function() {

    form_fields.form.submit(function (e) {

      e.preventDefault();

      if (form_fields.email_field.val().length && form_fields.checkbox.prop('checked')) {
        form_fields.btn.addClass('sending');
      }

      let ajax_data = {
        'action': 'md_public_ajax',
        'md_ajax_action': 'newsletter_subscribe',
        'data': {'email': form_fields.email_field.val()}
      };

      md_public_ajax(ajax_data, {
        message_container: form_fields.response_container
      });

      return false;

    });//end form submit

  });


})(jQuery); // Fully reference jQuery after this point.
